import axios from "axios";
import store from "@/state/store.js";

export function fetchMenus() {
    const userId = localStorage.getItem('userid');
    const ou = localStorage.getItem('activeOU');
    const url = `${process.env.VUE_APP_AUTHDOMAIN}/api/v1/settings/all-menus/${userId}`;
    return axios
        .get(url, {params: {ou_code: ou} })
        .then((response) => {
            if (response.data.data) {
                store.commit("setMenus", response.data.data);
            }
        })
        .catch((error) => {
            console.error("Error fetching menu:", error);
            return null;
        });
}
