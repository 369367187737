import { Ability, AbilityBuilder } from '@casl/ability';
import axios from "axios";
import store from "@/state/store.js";

const ability = new Ability();

// Define abilities using AbilityBuilder
const { rules } = new AbilityBuilder();

// // Define rules for the Ability instance
// can('read', 'Article');
// can('add', 'User');
// can('create', 'Article');
// cannot('delete', 'Article');

// Add the defined rules to the Ability instance
ability.update(rules);

export default ability;

/**fetch methods */
export function fetchRoles() {
    const userId = localStorage.getItem("userid");
    const url = `${process.env.VUE_APP_AUTHDOMAIN}/api/v1/settings/user-role/${userId}`;
    return axios
        .get(url)
        .then((response) => {
            if (response.data.data) {
                store.commit("setRoles", response.data.data.map(item => {
                    return item;
                }));
            }
        })
        .catch((error) => {
            console.error("Error fetching roles:", error);
            return null;
        });
}
export function fetchOUAccess() {
    const roles = localStorage.getItem("userRoles") ? JSON.parse(localStorage.getItem('userRoles')) : [];
    const url = `${process.env.VUE_APP_AUTHDOMAIN}/api/v1/settings/all-role-operation-units`;
    return axios
        .post(url, { role_ids: roles })
        .then((response) => {
            if (response.data.data) {
                store.commit('setOUAccess', response.data.data.map(item => {
                    return {
                        ou_code: item.ou_code,
                        ou_name: item.ou_name,
                        client_code: item.client_code
                    }
                }))
            }
        })
        .catch((error) => {
            console.error("Error fetching menu:", error);
            return null;
        });
}
export function fetchPermissions(){
    return axios.get(`${process.env.VUE_APP_AUTHDOMAIN}/api/v1/settings/permissions`)
    .then((response) => {
        console.log(response);
        if(response.data.data) {
            store.commit('setPermissions', response.data.data.map(item => { return item.code} ));
        }
    }).catch((error) => {
        console.error("Error fetching permissions:", error)
    });
}
